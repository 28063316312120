
import { defineComponent } from 'vue';
import Navigation from "./Navigation.vue"
import {useStore} from "vuex"
import {storeKey} from "@/store"

export default defineComponent({
  name: 'HeaderApp',
  components: {
    Navigation
  },

  data() {
    return {
      store: useStore(storeKey)
    }
  },

  computed: {
    title(): string {
      return this.store.state.site?.title || ""
    }
  }

});
