import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e41b633a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-header-app m-g-container" }
const _hoisted_2 = { class: "m-g-coll-2-12" }
const _hoisted_3 = { class: "m-g-coll-4-12" }
const _hoisted_4 = { class: "m-g-coll-6-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_navigation = _resolveComponent("navigation")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "link--no_style"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$route.name), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_navigation)
    ])
  ]))
}