import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c9d66c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$router.getRoutes(), (route) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (this.$route.name !== route.name)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: route.path
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}