
import { defineComponent } from 'vue';
import HeaderApp from "@/components/HeaderApp.vue"

export default defineComponent({
  name: 'App',

  components: {
    HeaderApp,
  },
});
